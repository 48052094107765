/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface CostHistory
 */
export interface CostHistory {
    /**
     * 
     * @type {number}
     * @memberof CostHistory
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostHistory
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostHistory
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostHistory
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostHistory
     */
    'factoryCostPerUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostHistory
     */
    'inboundCostPerUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostHistory
     */
    'totalCostPerUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostHistory
     */
    'unixCostDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof CostHistory
     */
    'costDate'?: string;
}
/**
 * 
 * @export
 * @interface CostsWithStats
 */
export interface CostsWithStats {
    /**
     * 
     * @type {PageLineCost}
     * @memberof CostsWithStats
     */
    'lineCosts'?: PageLineCost;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'costEntryCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'totalUnitsOrdered'?: number;
    /**
     * 
     * @type {string}
     * @memberof CostsWithStats
     */
    'lastCostUpdateDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'unixLastCostUpdateDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'totalFactoryCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'totalInboundCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'totalLandedCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'totalFactoryUnitCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'totalInboundUnitCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostsWithStats
     */
    'totalLandedUnitCosts'?: number;
    /**
     * 
     * @type {Product}
     * @memberof CostsWithStats
     */
    'product'?: Product;
}
/**
 * 
 * @export
 * @interface GoogleCodeRequestDto
 */
export interface GoogleCodeRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GoogleCodeRequestDto
     */
    'state'?: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleCodeRequestDto
     */
    'scopes'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleCodeRequestDto
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface GoogleOAuthToken
 */
export interface GoogleOAuthToken {
    /**
     * 
     * @type {number}
     * @memberof GoogleOAuthToken
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GoogleOAuthToken
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleOAuthToken
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleOAuthToken
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleOAuthToken
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleOAuthToken
     */
    'tokenType'?: string;
    /**
     * 
     * @type {number}
     * @memberof GoogleOAuthToken
     */
    'accessExpiresInSeconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof GoogleOAuthToken
     */
    'accessExpiresAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleOAuthToken
     */
    'tokenSet'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleOAuthToken
     */
    'scopes'?: string;
    /**
     * 
     * @type {number}
     * @memberof GoogleOAuthToken
     */
    'state'?: number;
    /**
     * 
     * @type {number}
     * @memberof GoogleOAuthToken
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface InventoryBalance
 */
export interface InventoryBalance {
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'packListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryBalance
     */
    'particulars'?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'costPerUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'weightedAverageCostPerUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'received'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'inventoryOnHand'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'sold'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalance
     */
    'unixOrderDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryBalance
     */
    'orderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryBalance
     */
    'inventoryBalanceType'?: InventoryBalanceInventoryBalanceTypeEnum;
    /**
     * 
     * @type {Array<InventoryBalanceLine>}
     * @memberof InventoryBalance
     */
    'inventoryBalanceLines'?: Array<InventoryBalanceLine>;
}

export const InventoryBalanceInventoryBalanceTypeEnum = {
    OpeningBalance: 'OPENING_BALANCE',
    InventoryOnHandUpdate: 'INVENTORY_ON_HAND_UPDATE',
    OrderReceived: 'ORDER_RECEIVED',
    Reconciliation: 'RECONCILIATION'
} as const;

export type InventoryBalanceInventoryBalanceTypeEnum = typeof InventoryBalanceInventoryBalanceTypeEnum[keyof typeof InventoryBalanceInventoryBalanceTypeEnum];

/**
 * 
 * @export
 * @interface InventoryBalanceContainerDto
 */
export interface InventoryBalanceContainerDto {
    /**
     * 
     * @type {number}
     * @memberof InventoryBalanceContainerDto
     */
    'productId'?: number;
    /**
     * 
     * @type {ProductBalanceSheetDto}
     * @memberof InventoryBalanceContainerDto
     */
    'balanceSheet'?: ProductBalanceSheetDto;
    /**
     * 
     * @type {PageInventoryBalance}
     * @memberof InventoryBalanceContainerDto
     */
    'inventoryBalances'?: PageInventoryBalance;
}
/**
 * 
 * @export
 * @interface InventoryBalanceLine
 */
export interface InventoryBalanceLine {
    /**
     * 
     * @type {number}
     * @memberof InventoryBalanceLine
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalanceLine
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalanceLine
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalanceLine
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalanceLine
     */
    'unitBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalanceLine
     */
    'stockCostPerUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryBalanceLine
     */
    'stockCost'?: number;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'unixDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'payReferenceNumber'?: string;
    /**
     * 
     * @type {Array<InvoiceLine>}
     * @memberof Invoice
     */
    'invoiceLines'?: Array<InvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'purchaseOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'shipmentPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'documentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'status'?: InvoiceStatusEnum;
}

export const InvoiceStatusEnum = {
    Review: 'REVIEW',
    Working: 'WORKING',
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type InvoiceStatusEnum = typeof InvoiceStatusEnum[keyof typeof InvoiceStatusEnum];

/**
 * 
 * @export
 * @interface InvoiceLine
 */
export interface InvoiceLine {
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    'internalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    'costType'?: InvoiceLineCostTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'perUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'amount'?: number;
    /**
     * 
     * @type {Product}
     * @memberof InvoiceLine
     */
    'product'?: Product;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLine
     */
    'isExcludedFromProductCostsData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLine
     */
    'isExcludedFromGeneralCostsData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'accountId'?: number;
}

export const InvoiceLineCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type InvoiceLineCostTypeEnum = typeof InvoiceLineCostTypeEnum[keyof typeof InvoiceLineCostTypeEnum];

/**
 * 
 * @export
 * @interface LineCost
 */
export interface LineCost {
    /**
     * 
     * @type {number}
     * @memberof LineCost
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LineCost
     */
    'internalReference'?: string;
    /**
     * 
     * @type {Product}
     * @memberof LineCost
     */
    'product'?: Product;
    /**
     * 
     * @type {Invoice}
     * @memberof LineCost
     */
    'invoice'?: Invoice;
    /**
     * 
     * @type {number}
     * @memberof LineCost
     */
    'factoryUnits'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCost
     */
    'factoryCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCost
     */
    'inboundCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCost
     */
    'totalCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCost
     */
    'quantityOrdered'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LineCost
     */
    'isExcludedFromProductCostsData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LineCost
     */
    'isExcludedFromGeneralCostsData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LineCost
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCost
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LineCost
     */
    'costType'?: LineCostCostTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LineCost
     */
    'invoiceLineId'?: number;
}

export const LineCostCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type LineCostCostTypeEnum = typeof LineCostCostTypeEnum[keyof typeof LineCostCostTypeEnum];

/**
 * 
 * @export
 * @interface LineCostList
 */
export interface LineCostList {
    /**
     * 
     * @type {PageLineCost}
     * @memberof LineCostList
     */
    'lineCosts'?: PageLineCost;
    /**
     * 
     * @type {number}
     * @memberof LineCostList
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCostList
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCostList
     */
    'totalUnitsOrdered'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCostList
     */
    'totalFactoryCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCostList
     */
    'totalInboundCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCostList
     */
    'totalLandedCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCostList
     */
    'averageCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineCostList
     */
    'costEntryCount'?: number;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationStatus'?: NotificationDtoNotificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationType'?: NotificationDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationSource'?: NotificationDtoNotificationSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'messageDateTime'?: string;
}

export const NotificationDtoNotificationStatusEnum = {
    Unread: 'UNREAD',
    Read: 'READ',
    Dismissed: 'DISMISSED'
} as const;

export type NotificationDtoNotificationStatusEnum = typeof NotificationDtoNotificationStatusEnum[keyof typeof NotificationDtoNotificationStatusEnum];
export const NotificationDtoNotificationTypeEnum = {
    Info: 'INFO',
    ActionNeeded: 'ACTION_NEEDED',
    Error: 'ERROR'
} as const;

export type NotificationDtoNotificationTypeEnum = typeof NotificationDtoNotificationTypeEnum[keyof typeof NotificationDtoNotificationTypeEnum];
export const NotificationDtoNotificationSourceEnum = {
    GatewayService: 'GATEWAY_SERVICE',
    InvoiceService: 'INVOICE_SERVICE',
    NotificationService: 'NOTIFICATION_SERVICE',
    ProductService: 'PRODUCT_SERVICE',
    ReportingService: 'REPORTING_SERVICE',
    SchedulerService: 'SCHEDULER_SERVICE',
    SupplierService: 'SUPPLIER_SERVICE',
    ShopifyService: 'SHOPIFY_SERVICE',
    AmazonService: 'AMAZON_SERVICE',
    UserService: 'USER_SERVICE',
    XeroService: 'XERO_SERVICE',
    OrdersService: 'ORDERS_SERVICE',
    Sv1Service: 'SV1_SERVICE',
    PurchasingService: 'PURCHASING_SERVICE'
} as const;

export type NotificationDtoNotificationSourceEnum = typeof NotificationDtoNotificationSourceEnum[keyof typeof NotificationDtoNotificationSourceEnum];

/**
 * 
 * @export
 * @interface PageCostHistory
 */
export interface PageCostHistory {
    /**
     * 
     * @type {number}
     * @memberof PageCostHistory
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCostHistory
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCostHistory
     */
    'size'?: number;
    /**
     * 
     * @type {Array<CostHistory>}
     * @memberof PageCostHistory
     */
    'content'?: Array<CostHistory>;
    /**
     * 
     * @type {number}
     * @memberof PageCostHistory
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageCostHistory
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageCostHistory
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageCostHistory
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageCostHistory
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCostHistory
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCostHistory
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageInventoryBalance
 */
export interface PageInventoryBalance {
    /**
     * 
     * @type {number}
     * @memberof PageInventoryBalance
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInventoryBalance
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInventoryBalance
     */
    'size'?: number;
    /**
     * 
     * @type {Array<InventoryBalance>}
     * @memberof PageInventoryBalance
     */
    'content'?: Array<InventoryBalance>;
    /**
     * 
     * @type {number}
     * @memberof PageInventoryBalance
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageInventoryBalance
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageInventoryBalance
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageInventoryBalance
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageInventoryBalance
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInventoryBalance
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInventoryBalance
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageLineCost
 */
export interface PageLineCost {
    /**
     * 
     * @type {number}
     * @memberof PageLineCost
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLineCost
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLineCost
     */
    'size'?: number;
    /**
     * 
     * @type {Array<LineCost>}
     * @memberof PageLineCost
     */
    'content'?: Array<LineCost>;
    /**
     * 
     * @type {number}
     * @memberof PageLineCost
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLineCost
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageLineCost
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageLineCost
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageLineCost
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLineCost
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLineCost
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMapStringObject
 */
export interface PageMapStringObject {
    /**
     * 
     * @type {number}
     * @memberof PageMapStringObject
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMapStringObject
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMapStringObject
     */
    'size'?: number;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof PageMapStringObject
     */
    'content'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {number}
     * @memberof PageMapStringObject
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageMapStringObject
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageMapStringObject
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageMapStringObject
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageMapStringObject
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMapStringObject
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMapStringObject
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageProduct
 */
export interface PageProduct {
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof PageProduct
     */
    'content'?: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageProduct
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageProduct
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageProduct
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProduct
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProduct
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageReceivedOrder
 */
export interface PageReceivedOrder {
    /**
     * 
     * @type {number}
     * @memberof PageReceivedOrder
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageReceivedOrder
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageReceivedOrder
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ReceivedOrder>}
     * @memberof PageReceivedOrder
     */
    'content'?: Array<ReceivedOrder>;
    /**
     * 
     * @type {number}
     * @memberof PageReceivedOrder
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageReceivedOrder
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageReceivedOrder
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageReceivedOrder
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageReceivedOrder
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageReceivedOrder
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageReceivedOrder
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'asin'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'fnSku'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'status'?: ProductStatusEnum;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    'products'?: Array<Product>;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isBundle'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'longestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'longestSideDimensionUnit'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'medianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'medianSideDimensionUnit'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'shortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'shortSideDimensionUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'dimensionUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'currency'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'changedInAmazon'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'amazonFees'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'amazonProductStatus'?: ProductAmazonProductStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'changeDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'listingPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'productSizeTier'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'perUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'perUnitWeightUnit'?: ProductPerUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'landedUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'costMargin'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estReferralFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estReferralFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'fbaFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'fbaFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estFeeTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estFeeTotalPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estVariableClosingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estVariableClosingFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estOrderHandlingFeePerOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estOrderHandlingFeePerOrderPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estPickPackFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estPickPackFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estWeightHandlingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estWeightHandlingFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'grossProfit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'grossMarginPercentage'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isVariant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'hasVariants'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'rootProductId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'variantNumber'?: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    'variants'?: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'numberOfGoodsOnOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'numberOfGoodsInTransit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'totalValueOfGoodsOnOrderAndInTransit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'openingInventoryOnHand'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'openingUnitCost'?: number;
}

export const ProductStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ProductStatusEnum = typeof ProductStatusEnum[keyof typeof ProductStatusEnum];
export const ProductAmazonProductStatusEnum = {
    NoChange: 'NO_CHANGE',
    NewChange: 'NEW_CHANGE',
    Accepted: 'ACCEPTED',
    OnHold: 'ON_HOLD',
    InReview: 'IN_REVIEW'
} as const;

export type ProductAmazonProductStatusEnum = typeof ProductAmazonProductStatusEnum[keyof typeof ProductAmazonProductStatusEnum];
export const ProductPerUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductPerUnitWeightUnitEnum = typeof ProductPerUnitWeightUnitEnum[keyof typeof ProductPerUnitWeightUnitEnum];

/**
 * 
 * @export
 * @interface ProductBalanceSheetDto
 */
export interface ProductBalanceSheetDto {
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'onOrderQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'inTransitQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'receivedQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'totalQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'onOrderAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'inTransitAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'receivedAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBalanceSheetDto
     */
    'landedUnitCost'?: number;
}
/**
 * 
 * @export
 * @interface ReceivedOrder
 */
export interface ReceivedOrder {
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'purchaseOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'shipmentPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'orderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'packListId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'quantityOrdered'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'quantityReceived'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'unixOrderDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReceivedOrder
     */
    'orderDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'factoryCostPerUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'inboundCostPerUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivedOrder
     */
    'totalCostPerUnit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReceivedOrder
     */
    'purchaseOrderNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivedOrder
     */
    'invoiceNumber'?: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
}

/**
 * GoogleSheetsControllerApi - axios parameter creator
 * @export
 */
export const GoogleSheetsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {GoogleCodeRequestDto} [googleCodeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewOAuthTokenForUserById: async (userId: number, googleCodeRequestDto?: GoogleCodeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createNewOAuthTokenForUserById', 'userId', userId)
            const localVarPath = `/reporting/{userId}/sheets/oauth/generate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleCodeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOAuthTokenByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteOAuthTokenByUserId', 'userId', userId)
            const localVarPath = `/reporting/{userId}/sheets/oauth`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthCodeRequestUrlForUserById: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAuthCodeRequestUrlForUserById', 'userId', userId)
            const localVarPath = `/reporting/{userId}/sheets/oauth/code-request-url`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuthTokenByUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOAuthTokenByUser', 'userId', userId)
            const localVarPath = `/reporting/{userId}/sheets/oauth`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllGoogleSheets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/reporting/sheets/sync-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllGoogleSheets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/reporting/sheets/update-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSheetFromData: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateSheetFromData', 'userId', userId)
            const localVarPath = `/reporting/{userId}/sheets/update`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoogleSheetsControllerApi - functional programming interface
 * @export
 */
export const GoogleSheetsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoogleSheetsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {GoogleCodeRequestDto} [googleCodeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewOAuthTokenForUserById(userId: number, googleCodeRequestDto?: GoogleCodeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleOAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewOAuthTokenForUserById(userId, googleCodeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleSheetsControllerApi.createNewOAuthTokenForUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOAuthTokenByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOAuthTokenByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleSheetsControllerApi.deleteOAuthTokenByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthCodeRequestUrlForUserById(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthCodeRequestUrlForUserById(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleSheetsControllerApi.getAuthCodeRequestUrlForUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOAuthTokenByUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GoogleOAuthToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOAuthTokenByUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleSheetsControllerApi.getOAuthTokenByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllGoogleSheets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllGoogleSheets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleSheetsControllerApi.syncAllGoogleSheets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllGoogleSheets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllGoogleSheets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleSheetsControllerApi.updateAllGoogleSheets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSheetFromData(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSheetFromData(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GoogleSheetsControllerApi.updateSheetFromData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GoogleSheetsControllerApi - factory interface
 * @export
 */
export const GoogleSheetsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoogleSheetsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {GoogleCodeRequestDto} [googleCodeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewOAuthTokenForUserById(userId: number, googleCodeRequestDto?: GoogleCodeRequestDto, options?: any): AxiosPromise<GoogleOAuthToken> {
            return localVarFp.createNewOAuthTokenForUserById(userId, googleCodeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOAuthTokenByUserId(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deleteOAuthTokenByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthCodeRequestUrlForUserById(userId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getAuthCodeRequestUrlForUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuthTokenByUser(userId: number, options?: any): AxiosPromise<Array<GoogleOAuthToken>> {
            return localVarFp.getOAuthTokenByUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllGoogleSheets(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.syncAllGoogleSheets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllGoogleSheets(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.updateAllGoogleSheets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSheetFromData(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.updateSheetFromData(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GoogleSheetsControllerApi - object-oriented interface
 * @export
 * @class GoogleSheetsControllerApi
 * @extends {BaseAPI}
 */
export class GoogleSheetsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {GoogleCodeRequestDto} [googleCodeRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsControllerApi
     */
    public createNewOAuthTokenForUserById(userId: number, googleCodeRequestDto?: GoogleCodeRequestDto, options?: RawAxiosRequestConfig) {
        return GoogleSheetsControllerApiFp(this.configuration).createNewOAuthTokenForUserById(userId, googleCodeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsControllerApi
     */
    public deleteOAuthTokenByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return GoogleSheetsControllerApiFp(this.configuration).deleteOAuthTokenByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsControllerApi
     */
    public getAuthCodeRequestUrlForUserById(userId: number, options?: RawAxiosRequestConfig) {
        return GoogleSheetsControllerApiFp(this.configuration).getAuthCodeRequestUrlForUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsControllerApi
     */
    public getOAuthTokenByUser(userId: number, options?: RawAxiosRequestConfig) {
        return GoogleSheetsControllerApiFp(this.configuration).getOAuthTokenByUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsControllerApi
     */
    public syncAllGoogleSheets(options?: RawAxiosRequestConfig) {
        return GoogleSheetsControllerApiFp(this.configuration).syncAllGoogleSheets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsControllerApi
     */
    public updateAllGoogleSheets(options?: RawAxiosRequestConfig) {
        return GoogleSheetsControllerApiFp(this.configuration).updateAllGoogleSheets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsControllerApi
     */
    public updateSheetFromData(userId: number, options?: RawAxiosRequestConfig) {
        return GoogleSheetsControllerApiFp(this.configuration).updateSheetFromData(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportingControllerApi - axios parameter creator
 * @export
 */
export const ReportingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {boolean} [changedInAmazon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAmazonProductsByUserId: async (userId: number, pageable: Pageable, changedInAmazon?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllAmazonProductsByUserId', 'userId', userId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getAllAmazonProductsByUserId', 'pageable', pageable)
            const localVarPath = `/reporting/{userId}/amazon-products`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (changedInAmazon !== undefined) {
                localVarQueryParameter['changedInAmazon'] = changedInAmazon;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductStatsByUserId: async (userId: number, pageable: Pageable, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllProductStatsByUserId', 'userId', userId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getAllProductStatsByUserId', 'pageable', pageable)
            const localVarPath = `/reporting/{userId}/products`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsedCostTypesByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllUsedCostTypesByUserId', 'userId', userId)
            const localVarPath = `/reporting/{userId}/cost-types`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostHistoriesForUserAndProduct: async (userId: number, productId: number, pageable: Pageable, startDate?: number, endDate?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCostHistoriesForUserAndProduct', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getCostHistoriesForUserAndProduct', 'productId', productId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getCostHistoriesForUserAndProduct', 'pageable', pageable)
            const localVarPath = `/reporting/{userId}/{productId}/cost-history`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostsByProductId: async (userId: number, productId: number, pageable: Pageable, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCostsByProductId', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getCostsByProductId', 'productId', productId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getCostsByProductId', 'pageable', pageable)
            const localVarPath = `/reporting/{userId}/costs/{productId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostsByUserId: async (userId: number, pageable: Pageable, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCostsByUserId', 'userId', userId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getCostsByUserId', 'pageable', pageable)
            const localVarPath = `/reporting/{userId}/costs`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventoryBalancesForUserAndProduct: async (userId: number, productId: number, pageable: Pageable, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getInventoryBalancesForUserAndProduct', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getInventoryBalancesForUserAndProduct', 'productId', productId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getInventoryBalancesForUserAndProduct', 'pageable', pageable)
            const localVarPath = `/reporting/{userId}/{productId}/inventory`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStatsById: async (userId: number, productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getProductStatsById', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductStatsById', 'productId', productId)
            const localVarPath = `/reporting/{userId}/products/{productId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivedOrdersForUserAndProduct: async (userId: number, productId: number, pageable: Pageable, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getReceivedOrdersForUserAndProduct', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getReceivedOrdersForUserAndProduct', 'productId', productId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getReceivedOrdersForUserAndProduct', 'pageable', pageable)
            const localVarPath = `/reporting/{userId}/{productId}/received-orders`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runInventoryBalanceSheetsImportForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('runInventoryBalanceSheetsImportForUser', 'userId', userId)
            const localVarPath = `/reporting/{userId}/run-inventory-balance-import`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (userId: number, notificationDto?: NotificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendNotification', 'userId', userId)
            const localVarPath = `/reporting/{userId}/notification`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportingControllerApi - functional programming interface
 * @export
 */
export const ReportingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {boolean} [changedInAmazon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAmazonProductsByUserId(userId: number, pageable: Pageable, changedInAmazon?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAmazonProductsByUserId(userId, pageable, changedInAmazon, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.getAllAmazonProductsByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProductStatsByUserId(userId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMapStringObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProductStatsByUserId(userId, pageable, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.getAllProductStatsByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsedCostTypesByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsedCostTypesByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.getAllUsedCostTypesByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostHistoriesForUserAndProduct(userId: number, productId: number, pageable: Pageable, startDate?: number, endDate?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCostHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostHistoriesForUserAndProduct(userId, productId, pageable, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.getCostHistoriesForUserAndProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostsByProductId(userId: number, productId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostsWithStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostsByProductId(userId, productId, pageable, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.getCostsByProductId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostsByUserId(userId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineCostList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostsByUserId(userId, pageable, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.getCostsByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInventoryBalancesForUserAndProduct(userId: number, productId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryBalanceContainerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryBalancesForUserAndProduct(userId, productId, pageable, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.getInventoryBalancesForUserAndProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductStatsById(userId: number, productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductStatsById(userId, productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.getProductStatsById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceivedOrdersForUserAndProduct(userId: number, productId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageReceivedOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceivedOrdersForUserAndProduct(userId, productId, pageable, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.getReceivedOrdersForUserAndProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runInventoryBalanceSheetsImportForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runInventoryBalanceSheetsImportForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.runInventoryBalanceSheetsImportForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(userId, notificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingControllerApi.sendNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportingControllerApi - factory interface
 * @export
 */
export const ReportingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {boolean} [changedInAmazon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAmazonProductsByUserId(userId: number, pageable: Pageable, changedInAmazon?: boolean, options?: any): AxiosPromise<PageProduct> {
            return localVarFp.getAllAmazonProductsByUserId(userId, pageable, changedInAmazon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductStatsByUserId(userId: number, pageable: Pageable, search?: string, options?: any): AxiosPromise<PageMapStringObject> {
            return localVarFp.getAllProductStatsByUserId(userId, pageable, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsedCostTypesByUserId(userId: number, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getAllUsedCostTypesByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostHistoriesForUserAndProduct(userId: number, productId: number, pageable: Pageable, startDate?: number, endDate?: number, options?: any): AxiosPromise<PageCostHistory> {
            return localVarFp.getCostHistoriesForUserAndProduct(userId, productId, pageable, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostsByProductId(userId: number, productId: number, pageable: Pageable, search?: string, options?: any): AxiosPromise<CostsWithStats> {
            return localVarFp.getCostsByProductId(userId, productId, pageable, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostsByUserId(userId: number, pageable: Pageable, search?: string, options?: any): AxiosPromise<LineCostList> {
            return localVarFp.getCostsByUserId(userId, pageable, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventoryBalancesForUserAndProduct(userId: number, productId: number, pageable: Pageable, search?: string, options?: any): AxiosPromise<InventoryBalanceContainerDto> {
            return localVarFp.getInventoryBalancesForUserAndProduct(userId, productId, pageable, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStatsById(userId: number, productId: number, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getProductStatsById(userId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivedOrdersForUserAndProduct(userId: number, productId: number, pageable: Pageable, search?: string, options?: any): AxiosPromise<PageReceivedOrder> {
            return localVarFp.getReceivedOrdersForUserAndProduct(userId, productId, pageable, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runInventoryBalanceSheetsImportForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.runInventoryBalanceSheetsImportForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(userId: number, notificationDto?: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return localVarFp.sendNotification(userId, notificationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportingControllerApi - object-oriented interface
 * @export
 * @class ReportingControllerApi
 * @extends {BaseAPI}
 */
export class ReportingControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {Pageable} pageable 
     * @param {boolean} [changedInAmazon] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public getAllAmazonProductsByUserId(userId: number, pageable: Pageable, changedInAmazon?: boolean, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).getAllAmazonProductsByUserId(userId, pageable, changedInAmazon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {Pageable} pageable 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public getAllProductStatsByUserId(userId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).getAllProductStatsByUserId(userId, pageable, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public getAllUsedCostTypesByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).getAllUsedCostTypesByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {Pageable} pageable 
     * @param {number} [startDate] 
     * @param {number} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public getCostHistoriesForUserAndProduct(userId: number, productId: number, pageable: Pageable, startDate?: number, endDate?: number, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).getCostHistoriesForUserAndProduct(userId, productId, pageable, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {Pageable} pageable 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public getCostsByProductId(userId: number, productId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).getCostsByProductId(userId, productId, pageable, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {Pageable} pageable 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public getCostsByUserId(userId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).getCostsByUserId(userId, pageable, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {Pageable} pageable 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public getInventoryBalancesForUserAndProduct(userId: number, productId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).getInventoryBalancesForUserAndProduct(userId, productId, pageable, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public getProductStatsById(userId: number, productId: number, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).getProductStatsById(userId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {Pageable} pageable 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public getReceivedOrdersForUserAndProduct(userId: number, productId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).getReceivedOrdersForUserAndProduct(userId, productId, pageable, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public runInventoryBalanceSheetsImportForUser(userId: number, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).runInventoryBalanceSheetsImportForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {NotificationDto} [notificationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig) {
        return ReportingControllerApiFp(this.configuration).sendNotification(userId, notificationDto, options).then((request) => request(this.axios, this.basePath));
    }
}




// src/axiosInstance.ts

import axios from 'axios'

import router from '@/router'
import store from '@/store'
export const setupAxios = () => {
  axios.interceptors.response.use(
    (response) => {
      // If the response is successful, just return the response
      return response
    },
    (error) => {
      // If the status code is 403, redirect to the login page
      if (error.response && error.response.status === 403) {
        store.dispatch('user/clearUser', null, { root: true })
        store.dispatch('auth/clearToken', null, { root: true })
        const message = "This action requires that we ask for your password after switching accounts. Please log in again and we'll bring you back to this page"
        store.dispatch('toasts/addError', { message })
        setTimeout(() => {
          router.push({ name: 'authLogin' })
        }, 2000)
      }
      // Return a rejected promise to handle the error in your application
      return Promise.reject(error)
    }
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencySymbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'sortField'?: number;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'precision'?: number;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'countryOfOrigin'?: OrderCountryOfOriginEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'productionLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'inTransitLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'totalLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'supplierTermsInDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'shippingMethod'?: OrderShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'hsCodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'tariffRates'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'unitsPerCase'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'totalCases'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'packagingLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'packagingLongestSideDimensionUnit'?: OrderPackagingLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'packagingMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'packagingMedianSideDimensionUnit'?: OrderPackagingMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'packagingShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'packagingShortSideDimensionUnit'?: OrderPackagingShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'packagingUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'packagingUnitWeightUnit'?: OrderPackagingUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'caseLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'caseLongestSideDimensionUnit'?: OrderCaseLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'caseMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'caseMedianSideDimensionUnit'?: OrderCaseMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'caseShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'caseShortSideDimensionUnit'?: OrderCaseShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'caseGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'caseGrossWeightUnit'?: OrderCaseGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'cubicMetres'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'totalGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'totalGrossWeightUnit'?: OrderTotalGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'invoiceLineId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'supplierId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'purchaseOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'unixLastUpdatedDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'lastUpdatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'poNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'unixOrderDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'unixShipDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'shipDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'unixReadyDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'readyDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'unixDeliveryDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'shippingTimeline'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'productionTimeline'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'totalLeadTimeline'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'paymentReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'shipmentPlanNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'productSku'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'supplierCompanyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'perUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'factoryCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'id'?: number;
    /**
     * 
     * @type {Array<PackList>}
     * @memberof Order
     */
    'packLists'?: Array<PackList>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderStatus'?: OrderOrderStatusEnum;
}

export const OrderCountryOfOriginEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type OrderCountryOfOriginEnum = typeof OrderCountryOfOriginEnum[keyof typeof OrderCountryOfOriginEnum];
export const OrderShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type OrderShippingMethodEnum = typeof OrderShippingMethodEnum[keyof typeof OrderShippingMethodEnum];
export const OrderPackagingLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderPackagingLongestSideDimensionUnitEnum = typeof OrderPackagingLongestSideDimensionUnitEnum[keyof typeof OrderPackagingLongestSideDimensionUnitEnum];
export const OrderPackagingMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderPackagingMedianSideDimensionUnitEnum = typeof OrderPackagingMedianSideDimensionUnitEnum[keyof typeof OrderPackagingMedianSideDimensionUnitEnum];
export const OrderPackagingShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderPackagingShortSideDimensionUnitEnum = typeof OrderPackagingShortSideDimensionUnitEnum[keyof typeof OrderPackagingShortSideDimensionUnitEnum];
export const OrderPackagingUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type OrderPackagingUnitWeightUnitEnum = typeof OrderPackagingUnitWeightUnitEnum[keyof typeof OrderPackagingUnitWeightUnitEnum];
export const OrderCaseLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderCaseLongestSideDimensionUnitEnum = typeof OrderCaseLongestSideDimensionUnitEnum[keyof typeof OrderCaseLongestSideDimensionUnitEnum];
export const OrderCaseMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderCaseMedianSideDimensionUnitEnum = typeof OrderCaseMedianSideDimensionUnitEnum[keyof typeof OrderCaseMedianSideDimensionUnitEnum];
export const OrderCaseShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderCaseShortSideDimensionUnitEnum = typeof OrderCaseShortSideDimensionUnitEnum[keyof typeof OrderCaseShortSideDimensionUnitEnum];
export const OrderCaseGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type OrderCaseGrossWeightUnitEnum = typeof OrderCaseGrossWeightUnitEnum[keyof typeof OrderCaseGrossWeightUnitEnum];
export const OrderTotalGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type OrderTotalGrossWeightUnitEnum = typeof OrderTotalGrossWeightUnitEnum[keyof typeof OrderTotalGrossWeightUnitEnum];
export const OrderOrderStatusEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED',
    Archived: 'ARCHIVED'
} as const;

export type OrderOrderStatusEnum = typeof OrderOrderStatusEnum[keyof typeof OrderOrderStatusEnum];

/**
 * 
 * @export
 * @interface OrderPatchPostDto
 */
export interface OrderPatchPostDto {
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'countryOfOrigin'?: OrderPatchPostDtoCountryOfOriginEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'productionLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'inTransitLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'supplierTermsInDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'shippingMethod'?: OrderPatchPostDtoShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'hsCodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'tariffRates'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'unitsPerCase'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'packagingLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'packagingLongestSideDimensionUnit'?: OrderPatchPostDtoPackagingLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'packagingMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'packagingMedianSideDimensionUnit'?: OrderPatchPostDtoPackagingMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'packagingShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'packagingShortSideDimensionUnit'?: OrderPatchPostDtoPackagingShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'packagingUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'packagingUnitWeightUnit'?: OrderPatchPostDtoPackagingUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'caseLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'caseLongestSideDimensionUnit'?: OrderPatchPostDtoCaseLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'caseMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'caseMedianSideDimensionUnit'?: OrderPatchPostDtoCaseMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'caseShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'caseShortSideDimensionUnit'?: OrderPatchPostDtoCaseShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'caseGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPatchPostDto
     */
    'caseGrossWeightUnit'?: OrderPatchPostDtoCaseGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'cubicMetres'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'unixOrderDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'unixShipDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'unixReadyDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'unixDeliveryDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPatchPostDto
     */
    'perUnitCost'?: number;
    /**
     * 
     * @type {Array<PackList>}
     * @memberof OrderPatchPostDto
     */
    'packLists'?: Array<PackList>;
}

export const OrderPatchPostDtoCountryOfOriginEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type OrderPatchPostDtoCountryOfOriginEnum = typeof OrderPatchPostDtoCountryOfOriginEnum[keyof typeof OrderPatchPostDtoCountryOfOriginEnum];
export const OrderPatchPostDtoShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type OrderPatchPostDtoShippingMethodEnum = typeof OrderPatchPostDtoShippingMethodEnum[keyof typeof OrderPatchPostDtoShippingMethodEnum];
export const OrderPatchPostDtoPackagingLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderPatchPostDtoPackagingLongestSideDimensionUnitEnum = typeof OrderPatchPostDtoPackagingLongestSideDimensionUnitEnum[keyof typeof OrderPatchPostDtoPackagingLongestSideDimensionUnitEnum];
export const OrderPatchPostDtoPackagingMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderPatchPostDtoPackagingMedianSideDimensionUnitEnum = typeof OrderPatchPostDtoPackagingMedianSideDimensionUnitEnum[keyof typeof OrderPatchPostDtoPackagingMedianSideDimensionUnitEnum];
export const OrderPatchPostDtoPackagingShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderPatchPostDtoPackagingShortSideDimensionUnitEnum = typeof OrderPatchPostDtoPackagingShortSideDimensionUnitEnum[keyof typeof OrderPatchPostDtoPackagingShortSideDimensionUnitEnum];
export const OrderPatchPostDtoPackagingUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type OrderPatchPostDtoPackagingUnitWeightUnitEnum = typeof OrderPatchPostDtoPackagingUnitWeightUnitEnum[keyof typeof OrderPatchPostDtoPackagingUnitWeightUnitEnum];
export const OrderPatchPostDtoCaseLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderPatchPostDtoCaseLongestSideDimensionUnitEnum = typeof OrderPatchPostDtoCaseLongestSideDimensionUnitEnum[keyof typeof OrderPatchPostDtoCaseLongestSideDimensionUnitEnum];
export const OrderPatchPostDtoCaseMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderPatchPostDtoCaseMedianSideDimensionUnitEnum = typeof OrderPatchPostDtoCaseMedianSideDimensionUnitEnum[keyof typeof OrderPatchPostDtoCaseMedianSideDimensionUnitEnum];
export const OrderPatchPostDtoCaseShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type OrderPatchPostDtoCaseShortSideDimensionUnitEnum = typeof OrderPatchPostDtoCaseShortSideDimensionUnitEnum[keyof typeof OrderPatchPostDtoCaseShortSideDimensionUnitEnum];
export const OrderPatchPostDtoCaseGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type OrderPatchPostDtoCaseGrossWeightUnitEnum = typeof OrderPatchPostDtoCaseGrossWeightUnitEnum[keyof typeof OrderPatchPostDtoCaseGrossWeightUnitEnum];

/**
 * 
 * @export
 * @interface OrdersListDto
 */
export interface OrdersListDto {
    /**
     * 
     * @type {PageOrder}
     * @memberof OrdersListDto
     */
    'orders'?: PageOrder;
    /**
     * 
     * @type {number}
     * @memberof OrdersListDto
     */
    'orderQuantityTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersListDto
     */
    'totalCasesTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersListDto
     */
    'factoryCostTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersListDto
     */
    'cubicMetresTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersListDto
     */
    'totalGrossWeightTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrdersListDto
     */
    'totalGrossWeightTotalUnit'?: OrdersListDtoTotalGrossWeightTotalUnitEnum;
}

export const OrdersListDtoTotalGrossWeightTotalUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type OrdersListDtoTotalGrossWeightTotalUnitEnum = typeof OrdersListDtoTotalGrossWeightTotalUnitEnum[keyof typeof OrdersListDtoTotalGrossWeightTotalUnitEnum];

/**
 * 
 * @export
 * @interface PackList
 */
export interface PackList {
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'countryOfOrigin'?: PackListCountryOfOriginEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'productionLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'inTransitLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'totalLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'supplierTermsInDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'shippingMethod'?: PackListShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'hsCodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'tariffRates'?: string;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'unitsPerCase'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'totalCases'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'packagingLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'packagingLongestSideDimensionUnit'?: PackListPackagingLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'packagingMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'packagingMedianSideDimensionUnit'?: PackListPackagingMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'packagingShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'packagingShortSideDimensionUnit'?: PackListPackagingShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'packagingUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'packagingUnitWeightUnit'?: PackListPackagingUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'caseLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'caseLongestSideDimensionUnit'?: PackListCaseLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'caseMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'caseMedianSideDimensionUnit'?: PackListCaseMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'caseShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'caseShortSideDimensionUnit'?: PackListCaseShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'caseGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'caseGrossWeightUnit'?: PackListCaseGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'cubicMetres'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'totalGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'totalGrossWeightUnit'?: PackListTotalGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'invoiceLineId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'supplierId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'purchaseOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'unixLastUpdatedDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'lastUpdatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'poNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'unixOrderDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'orderDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'unixShipDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'shipDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'unixReadyDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'readyDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'unixDeliveryDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'shippingTimeline'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'productionTimeline'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'totalLeadTimeline'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'paymentReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'shipmentPlanNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'productSku'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'supplierCompanyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'perUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'factoryCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'transientOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'shipmentPlanId'?: number;
    /**
     * 
     * @type {ShipmentPlan}
     * @memberof PackList
     */
    'shipmentPlan'?: ShipmentPlan;
    /**
     * 
     * @type {string}
     * @memberof PackList
     */
    'packListStatus'?: PackListPackListStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PackList
     */
    'unixActualReceivedDate'?: number;
}

export const PackListCountryOfOriginEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type PackListCountryOfOriginEnum = typeof PackListCountryOfOriginEnum[keyof typeof PackListCountryOfOriginEnum];
export const PackListShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type PackListShippingMethodEnum = typeof PackListShippingMethodEnum[keyof typeof PackListShippingMethodEnum];
export const PackListPackagingLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListPackagingLongestSideDimensionUnitEnum = typeof PackListPackagingLongestSideDimensionUnitEnum[keyof typeof PackListPackagingLongestSideDimensionUnitEnum];
export const PackListPackagingMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListPackagingMedianSideDimensionUnitEnum = typeof PackListPackagingMedianSideDimensionUnitEnum[keyof typeof PackListPackagingMedianSideDimensionUnitEnum];
export const PackListPackagingShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListPackagingShortSideDimensionUnitEnum = typeof PackListPackagingShortSideDimensionUnitEnum[keyof typeof PackListPackagingShortSideDimensionUnitEnum];
export const PackListPackagingUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type PackListPackagingUnitWeightUnitEnum = typeof PackListPackagingUnitWeightUnitEnum[keyof typeof PackListPackagingUnitWeightUnitEnum];
export const PackListCaseLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListCaseLongestSideDimensionUnitEnum = typeof PackListCaseLongestSideDimensionUnitEnum[keyof typeof PackListCaseLongestSideDimensionUnitEnum];
export const PackListCaseMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListCaseMedianSideDimensionUnitEnum = typeof PackListCaseMedianSideDimensionUnitEnum[keyof typeof PackListCaseMedianSideDimensionUnitEnum];
export const PackListCaseShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListCaseShortSideDimensionUnitEnum = typeof PackListCaseShortSideDimensionUnitEnum[keyof typeof PackListCaseShortSideDimensionUnitEnum];
export const PackListCaseGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type PackListCaseGrossWeightUnitEnum = typeof PackListCaseGrossWeightUnitEnum[keyof typeof PackListCaseGrossWeightUnitEnum];
export const PackListTotalGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type PackListTotalGrossWeightUnitEnum = typeof PackListTotalGrossWeightUnitEnum[keyof typeof PackListTotalGrossWeightUnitEnum];
export const PackListPackListStatusEnum = {
    Open: 'OPEN',
    InShipmentPlanning: 'IN_SHIPMENT_PLANNING',
    InTransit: 'IN_TRANSIT',
    Received: 'RECEIVED',
    Closed: 'CLOSED',
    Archived: 'ARCHIVED'
} as const;

export type PackListPackListStatusEnum = typeof PackListPackListStatusEnum[keyof typeof PackListPackListStatusEnum];

/**
 * 
 * @export
 * @interface PackListMultipleDeleteDto
 */
export interface PackListMultipleDeleteDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof PackListMultipleDeleteDto
     */
    'packListIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface PackListPatchPostDto
 */
export interface PackListPatchPostDto {
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'countryOfOrigin'?: PackListPatchPostDtoCountryOfOriginEnum;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'productionLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'inTransitLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'supplierTermsInDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'shippingMethod'?: PackListPatchPostDtoShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'hsCodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'tariffRates'?: string;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'unitsPerCase'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'packagingLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'packagingLongestSideDimensionUnit'?: PackListPatchPostDtoPackagingLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'packagingMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'packagingMedianSideDimensionUnit'?: PackListPatchPostDtoPackagingMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'packagingShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'packagingShortSideDimensionUnit'?: PackListPatchPostDtoPackagingShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'packagingUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'packagingUnitWeightUnit'?: PackListPatchPostDtoPackagingUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'caseLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'caseLongestSideDimensionUnit'?: PackListPatchPostDtoCaseLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'caseMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'caseMedianSideDimensionUnit'?: PackListPatchPostDtoCaseMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'caseShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'caseShortSideDimensionUnit'?: PackListPatchPostDtoCaseShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'caseGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackListPatchPostDto
     */
    'caseGrossWeightUnit'?: PackListPatchPostDtoCaseGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'cubicMetres'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'unixOrderDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'unixShipDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'unixReadyDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'unixDeliveryDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PackListPatchPostDto
     */
    'perUnitCost'?: number;
}

export const PackListPatchPostDtoCountryOfOriginEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type PackListPatchPostDtoCountryOfOriginEnum = typeof PackListPatchPostDtoCountryOfOriginEnum[keyof typeof PackListPatchPostDtoCountryOfOriginEnum];
export const PackListPatchPostDtoShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type PackListPatchPostDtoShippingMethodEnum = typeof PackListPatchPostDtoShippingMethodEnum[keyof typeof PackListPatchPostDtoShippingMethodEnum];
export const PackListPatchPostDtoPackagingLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListPatchPostDtoPackagingLongestSideDimensionUnitEnum = typeof PackListPatchPostDtoPackagingLongestSideDimensionUnitEnum[keyof typeof PackListPatchPostDtoPackagingLongestSideDimensionUnitEnum];
export const PackListPatchPostDtoPackagingMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListPatchPostDtoPackagingMedianSideDimensionUnitEnum = typeof PackListPatchPostDtoPackagingMedianSideDimensionUnitEnum[keyof typeof PackListPatchPostDtoPackagingMedianSideDimensionUnitEnum];
export const PackListPatchPostDtoPackagingShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListPatchPostDtoPackagingShortSideDimensionUnitEnum = typeof PackListPatchPostDtoPackagingShortSideDimensionUnitEnum[keyof typeof PackListPatchPostDtoPackagingShortSideDimensionUnitEnum];
export const PackListPatchPostDtoPackagingUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type PackListPatchPostDtoPackagingUnitWeightUnitEnum = typeof PackListPatchPostDtoPackagingUnitWeightUnitEnum[keyof typeof PackListPatchPostDtoPackagingUnitWeightUnitEnum];
export const PackListPatchPostDtoCaseLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListPatchPostDtoCaseLongestSideDimensionUnitEnum = typeof PackListPatchPostDtoCaseLongestSideDimensionUnitEnum[keyof typeof PackListPatchPostDtoCaseLongestSideDimensionUnitEnum];
export const PackListPatchPostDtoCaseMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListPatchPostDtoCaseMedianSideDimensionUnitEnum = typeof PackListPatchPostDtoCaseMedianSideDimensionUnitEnum[keyof typeof PackListPatchPostDtoCaseMedianSideDimensionUnitEnum];
export const PackListPatchPostDtoCaseShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type PackListPatchPostDtoCaseShortSideDimensionUnitEnum = typeof PackListPatchPostDtoCaseShortSideDimensionUnitEnum[keyof typeof PackListPatchPostDtoCaseShortSideDimensionUnitEnum];
export const PackListPatchPostDtoCaseGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type PackListPatchPostDtoCaseGrossWeightUnitEnum = typeof PackListPatchPostDtoCaseGrossWeightUnitEnum[keyof typeof PackListPatchPostDtoCaseGrossWeightUnitEnum];

/**
 * 
 * @export
 * @interface PageOrder
 */
export interface PageOrder {
    /**
     * 
     * @type {number}
     * @memberof PageOrder
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrder
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrder
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Order>}
     * @memberof PageOrder
     */
    'content'?: Array<Order>;
    /**
     * 
     * @type {number}
     * @memberof PageOrder
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOrder
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrder
     */
    'first'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOrder
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOrder
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrder
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrder
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageShipmentPlan
 */
export interface PageShipmentPlan {
    /**
     * 
     * @type {number}
     * @memberof PageShipmentPlan
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageShipmentPlan
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageShipmentPlan
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ShipmentPlan>}
     * @memberof PageShipmentPlan
     */
    'content'?: Array<ShipmentPlan>;
    /**
     * 
     * @type {number}
     * @memberof PageShipmentPlan
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageShipmentPlan
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageShipmentPlan
     */
    'first'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageShipmentPlan
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageShipmentPlan
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageShipmentPlan
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageShipmentPlan
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'asin'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'fnSku'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'status'?: ProductStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isBundle'?: boolean;
    /**
     * 
     * @type {Array<ProductSupplier>}
     * @memberof Product
     */
    'productSuppliers'?: Array<ProductSupplier>;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isVariant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'hasVariants'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'rootProductId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'longestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'longestSideDimensionUnit'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'medianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'medianSideDimensionUnit'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'shortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'shortSideDimensionUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'dimensionUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'currency'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'changedInAmazon'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'perUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'perUnitWeightUnit'?: ProductPerUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'packagingWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'packagingWeightUnit'?: ProductPackagingWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'numberOfGoodsOnOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'numberOfGoodsInTransit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'totalValueOfGoodsOnOrderAndInTransit'?: number;
}

export const ProductStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ProductStatusEnum = typeof ProductStatusEnum[keyof typeof ProductStatusEnum];
export const ProductPerUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductPerUnitWeightUnitEnum = typeof ProductPerUnitWeightUnitEnum[keyof typeof ProductPerUnitWeightUnitEnum];
export const ProductPackagingWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductPackagingWeightUnitEnum = typeof ProductPackagingWeightUnitEnum[keyof typeof ProductPackagingWeightUnitEnum];

/**
 * 
 * @export
 * @interface ProductSupplier
 */
export interface ProductSupplier {
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'countryOfOrigin'?: ProductSupplierCountryOfOriginEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'productionLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'inTransitLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'totalLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'supplierTermsInDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'shippingMethod'?: ProductSupplierShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'hsCodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'tariffRates'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'unitsPerCase'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'totalCases'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingLongestSideDimensionUnit'?: ProductSupplierPackagingLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingMedianSideDimensionUnit'?: ProductSupplierPackagingMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingShortSideDimensionUnit'?: ProductSupplierPackagingShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingUnitWeightUnit'?: ProductSupplierPackagingUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseLongestSideDimensionUnit'?: ProductSupplierCaseLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseMedianSideDimensionUnit'?: ProductSupplierCaseMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseShortSideDimensionUnit'?: ProductSupplierCaseShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseGrossWeightUnit'?: ProductSupplierCaseGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'cubicMetres'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'totalGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'totalGrossWeightUnit'?: ProductSupplierTotalGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'id'?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductSupplier
     */
    'product'?: Product;
    /**
     * 
     * @type {Supplier}
     * @memberof ProductSupplier
     */
    'supplier'?: Supplier;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'leadTime'?: number;
}

export const ProductSupplierCountryOfOriginEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type ProductSupplierCountryOfOriginEnum = typeof ProductSupplierCountryOfOriginEnum[keyof typeof ProductSupplierCountryOfOriginEnum];
export const ProductSupplierShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type ProductSupplierShippingMethodEnum = typeof ProductSupplierShippingMethodEnum[keyof typeof ProductSupplierShippingMethodEnum];
export const ProductSupplierPackagingLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ProductSupplierPackagingLongestSideDimensionUnitEnum = typeof ProductSupplierPackagingLongestSideDimensionUnitEnum[keyof typeof ProductSupplierPackagingLongestSideDimensionUnitEnum];
export const ProductSupplierPackagingMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ProductSupplierPackagingMedianSideDimensionUnitEnum = typeof ProductSupplierPackagingMedianSideDimensionUnitEnum[keyof typeof ProductSupplierPackagingMedianSideDimensionUnitEnum];
export const ProductSupplierPackagingShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ProductSupplierPackagingShortSideDimensionUnitEnum = typeof ProductSupplierPackagingShortSideDimensionUnitEnum[keyof typeof ProductSupplierPackagingShortSideDimensionUnitEnum];
export const ProductSupplierPackagingUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductSupplierPackagingUnitWeightUnitEnum = typeof ProductSupplierPackagingUnitWeightUnitEnum[keyof typeof ProductSupplierPackagingUnitWeightUnitEnum];
export const ProductSupplierCaseLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ProductSupplierCaseLongestSideDimensionUnitEnum = typeof ProductSupplierCaseLongestSideDimensionUnitEnum[keyof typeof ProductSupplierCaseLongestSideDimensionUnitEnum];
export const ProductSupplierCaseMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ProductSupplierCaseMedianSideDimensionUnitEnum = typeof ProductSupplierCaseMedianSideDimensionUnitEnum[keyof typeof ProductSupplierCaseMedianSideDimensionUnitEnum];
export const ProductSupplierCaseShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ProductSupplierCaseShortSideDimensionUnitEnum = typeof ProductSupplierCaseShortSideDimensionUnitEnum[keyof typeof ProductSupplierCaseShortSideDimensionUnitEnum];
export const ProductSupplierCaseGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductSupplierCaseGrossWeightUnitEnum = typeof ProductSupplierCaseGrossWeightUnitEnum[keyof typeof ProductSupplierCaseGrossWeightUnitEnum];
export const ProductSupplierTotalGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductSupplierTotalGrossWeightUnitEnum = typeof ProductSupplierTotalGrossWeightUnitEnum[keyof typeof ProductSupplierTotalGrossWeightUnitEnum];

/**
 * 
 * @export
 * @interface ShipmentPlan
 */
export interface ShipmentPlan {
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'countryOfOrigin'?: ShipmentPlanCountryOfOriginEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'productionLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'inTransitLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'totalLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'supplierTermsInDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'shippingMethod'?: ShipmentPlanShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'hsCodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'tariffRates'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'unitsPerCase'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'totalCases'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'packagingLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'packagingLongestSideDimensionUnit'?: ShipmentPlanPackagingLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'packagingMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'packagingMedianSideDimensionUnit'?: ShipmentPlanPackagingMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'packagingShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'packagingShortSideDimensionUnit'?: ShipmentPlanPackagingShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'packagingUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'packagingUnitWeightUnit'?: ShipmentPlanPackagingUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'caseLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'caseLongestSideDimensionUnit'?: ShipmentPlanCaseLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'caseMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'caseMedianSideDimensionUnit'?: ShipmentPlanCaseMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'caseShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'caseShortSideDimensionUnit'?: ShipmentPlanCaseShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'caseGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'caseGrossWeightUnit'?: ShipmentPlanCaseGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'cubicMetres'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'totalGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'totalGrossWeightUnit'?: ShipmentPlanTotalGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'shipmentPlanStatus'?: ShipmentPlanShipmentPlanStatusEnum;
    /**
     * 
     * @type {Array<PackList>}
     * @memberof ShipmentPlan
     */
    'packLists'?: Array<PackList>;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'containerType'?: ShipmentPlanContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'calculateBy'?: ShipmentPlanCalculateByEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'shippingRateOrContainerEstimate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'fromAddressType'?: ShipmentPlanFromAddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'fromAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'fromCountry'?: ShipmentPlanFromCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'toAddressType'?: ShipmentPlanToAddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'toAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'toCountry'?: ShipmentPlanToCountryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentPlan
     */
    'containsHazardousGoods'?: boolean;
    /**
     * 
     * @type {Currency}
     * @memberof ShipmentPlan
     */
    'currency'?: Currency;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'goodsTimeline'?: ShipmentPlanGoodsTimelineEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'unixEstimatedShipDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'estimatedShipDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'unixEstimatedReadyDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'estimatedReadyDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'unixEstimatedDeliveryDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'estimatedDeliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'shippingTimeline'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'productionTimeline'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'estimatedShippingCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'totalShippedCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'totalCubicMetres'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'jobNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'carrierContact'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlan
     */
    'warehouseReference'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'chargeableWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'shippingRate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentPlan
     */
    'isInTransitNotificationSent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentPlan
     */
    'isReceivedNotificationSent'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlan
     */
    'unixActualReceivedDate'?: number;
}

export const ShipmentPlanCountryOfOriginEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type ShipmentPlanCountryOfOriginEnum = typeof ShipmentPlanCountryOfOriginEnum[keyof typeof ShipmentPlanCountryOfOriginEnum];
export const ShipmentPlanShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type ShipmentPlanShippingMethodEnum = typeof ShipmentPlanShippingMethodEnum[keyof typeof ShipmentPlanShippingMethodEnum];
export const ShipmentPlanPackagingLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ShipmentPlanPackagingLongestSideDimensionUnitEnum = typeof ShipmentPlanPackagingLongestSideDimensionUnitEnum[keyof typeof ShipmentPlanPackagingLongestSideDimensionUnitEnum];
export const ShipmentPlanPackagingMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ShipmentPlanPackagingMedianSideDimensionUnitEnum = typeof ShipmentPlanPackagingMedianSideDimensionUnitEnum[keyof typeof ShipmentPlanPackagingMedianSideDimensionUnitEnum];
export const ShipmentPlanPackagingShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ShipmentPlanPackagingShortSideDimensionUnitEnum = typeof ShipmentPlanPackagingShortSideDimensionUnitEnum[keyof typeof ShipmentPlanPackagingShortSideDimensionUnitEnum];
export const ShipmentPlanPackagingUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ShipmentPlanPackagingUnitWeightUnitEnum = typeof ShipmentPlanPackagingUnitWeightUnitEnum[keyof typeof ShipmentPlanPackagingUnitWeightUnitEnum];
export const ShipmentPlanCaseLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ShipmentPlanCaseLongestSideDimensionUnitEnum = typeof ShipmentPlanCaseLongestSideDimensionUnitEnum[keyof typeof ShipmentPlanCaseLongestSideDimensionUnitEnum];
export const ShipmentPlanCaseMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ShipmentPlanCaseMedianSideDimensionUnitEnum = typeof ShipmentPlanCaseMedianSideDimensionUnitEnum[keyof typeof ShipmentPlanCaseMedianSideDimensionUnitEnum];
export const ShipmentPlanCaseShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type ShipmentPlanCaseShortSideDimensionUnitEnum = typeof ShipmentPlanCaseShortSideDimensionUnitEnum[keyof typeof ShipmentPlanCaseShortSideDimensionUnitEnum];
export const ShipmentPlanCaseGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ShipmentPlanCaseGrossWeightUnitEnum = typeof ShipmentPlanCaseGrossWeightUnitEnum[keyof typeof ShipmentPlanCaseGrossWeightUnitEnum];
export const ShipmentPlanTotalGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ShipmentPlanTotalGrossWeightUnitEnum = typeof ShipmentPlanTotalGrossWeightUnitEnum[keyof typeof ShipmentPlanTotalGrossWeightUnitEnum];
export const ShipmentPlanShipmentPlanStatusEnum = {
    Open: 'OPEN',
    InTransit: 'IN_TRANSIT',
    Received: 'RECEIVED',
    Closed: 'CLOSED',
    Archived: 'ARCHIVED'
} as const;

export type ShipmentPlanShipmentPlanStatusEnum = typeof ShipmentPlanShipmentPlanStatusEnum[keyof typeof ShipmentPlanShipmentPlanStatusEnum];
export const ShipmentPlanContainerTypeEnum = {
    IndividualCases: 'INDIVIDUAL_CASES',
    Containers20Ft: 'CONTAINERS_20_FT',
    Containers40Ft: 'CONTAINERS_40_FT',
    Containers40FtHc: 'CONTAINERS_40_FT_HC',
    Containers45FtHc: 'CONTAINERS_45_FT_HC'
} as const;

export type ShipmentPlanContainerTypeEnum = typeof ShipmentPlanContainerTypeEnum[keyof typeof ShipmentPlanContainerTypeEnum];
export const ShipmentPlanCalculateByEnum = {
    Cbm: 'CBM',
    VolumetricWeight: 'VOLUMETRIC_WEIGHT',
    PerUnit: 'PER_UNIT'
} as const;

export type ShipmentPlanCalculateByEnum = typeof ShipmentPlanCalculateByEnum[keyof typeof ShipmentPlanCalculateByEnum];
export const ShipmentPlanFromAddressTypeEnum = {
    FactoryOrWarehouse: 'FACTORY_OR_WAREHOUSE',
    PortOrAirport: 'PORT_OR_AIRPORT',
    BusinessAddress: 'BUSINESS_ADDRESS',
    ResidentialAddress: 'RESIDENTIAL_ADDRESS'
} as const;

export type ShipmentPlanFromAddressTypeEnum = typeof ShipmentPlanFromAddressTypeEnum[keyof typeof ShipmentPlanFromAddressTypeEnum];
export const ShipmentPlanFromCountryEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type ShipmentPlanFromCountryEnum = typeof ShipmentPlanFromCountryEnum[keyof typeof ShipmentPlanFromCountryEnum];
export const ShipmentPlanToAddressTypeEnum = {
    FactoryOrWarehouse: 'FACTORY_OR_WAREHOUSE',
    PortOrAirport: 'PORT_OR_AIRPORT',
    BusinessAddress: 'BUSINESS_ADDRESS',
    ResidentialAddress: 'RESIDENTIAL_ADDRESS'
} as const;

export type ShipmentPlanToAddressTypeEnum = typeof ShipmentPlanToAddressTypeEnum[keyof typeof ShipmentPlanToAddressTypeEnum];
export const ShipmentPlanToCountryEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type ShipmentPlanToCountryEnum = typeof ShipmentPlanToCountryEnum[keyof typeof ShipmentPlanToCountryEnum];
export const ShipmentPlanGoodsTimelineEnum = {
    AReadyNow: 'A_READY_NOW',
    BReadyWithinTwoWeeks: 'B_READY_WITHIN_TWO_WEEKS',
    CReadyInMoreThanTwoWeeks: 'C_READY_IN_MORE_THAN_TWO_WEEKS'
} as const;

export type ShipmentPlanGoodsTimelineEnum = typeof ShipmentPlanGoodsTimelineEnum[keyof typeof ShipmentPlanGoodsTimelineEnum];

/**
 * 
 * @export
 * @interface ShipmentPlanPackListsDto
 */
export interface ShipmentPlanPackListsDto {
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPackListsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPackListsDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShipmentPlanPackListsDto
     */
    'packListIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ShipmentPlanPatchDto
 */
export interface ShipmentPlanPatchDto {
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'shipmentPlanStatus'?: ShipmentPlanPatchDtoShipmentPlanStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'containerType'?: ShipmentPlanPatchDtoContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'calculateBy'?: ShipmentPlanPatchDtoCalculateByEnum;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPatchDto
     */
    'shippingRateOrContainerEstimate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'fromAddressType'?: ShipmentPlanPatchDtoFromAddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'fromAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'fromCountry'?: ShipmentPlanPatchDtoFromCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'toAddressType'?: ShipmentPlanPatchDtoToAddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'toAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'toCountry'?: ShipmentPlanPatchDtoToCountryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentPlanPatchDto
     */
    'containsHazardousGoods'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPatchDto
     */
    'goodsValue'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof ShipmentPlanPatchDto
     */
    'currency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPatchDto
     */
    'unixEstimatedReadyDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPatchDto
     */
    'unixEstimatedShipDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPatchDto
     */
    'unixEstimatedDeliveryDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPatchDto
     */
    'volumetricWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPatchDto
     */
    'estimatedShippingCost'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'jobNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'carrierContact'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanPatchDto
     */
    'warehouseReference'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPatchDto
     */
    'chargeableWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanPatchDto
     */
    'shippingRate'?: number;
}

export const ShipmentPlanPatchDtoShipmentPlanStatusEnum = {
    Open: 'OPEN',
    InTransit: 'IN_TRANSIT',
    Received: 'RECEIVED',
    Closed: 'CLOSED',
    Archived: 'ARCHIVED'
} as const;

export type ShipmentPlanPatchDtoShipmentPlanStatusEnum = typeof ShipmentPlanPatchDtoShipmentPlanStatusEnum[keyof typeof ShipmentPlanPatchDtoShipmentPlanStatusEnum];
export const ShipmentPlanPatchDtoContainerTypeEnum = {
    IndividualCases: 'INDIVIDUAL_CASES',
    Containers20Ft: 'CONTAINERS_20_FT',
    Containers40Ft: 'CONTAINERS_40_FT',
    Containers40FtHc: 'CONTAINERS_40_FT_HC',
    Containers45FtHc: 'CONTAINERS_45_FT_HC'
} as const;

export type ShipmentPlanPatchDtoContainerTypeEnum = typeof ShipmentPlanPatchDtoContainerTypeEnum[keyof typeof ShipmentPlanPatchDtoContainerTypeEnum];
export const ShipmentPlanPatchDtoCalculateByEnum = {
    Cbm: 'CBM',
    VolumetricWeight: 'VOLUMETRIC_WEIGHT',
    PerUnit: 'PER_UNIT'
} as const;

export type ShipmentPlanPatchDtoCalculateByEnum = typeof ShipmentPlanPatchDtoCalculateByEnum[keyof typeof ShipmentPlanPatchDtoCalculateByEnum];
export const ShipmentPlanPatchDtoFromAddressTypeEnum = {
    FactoryOrWarehouse: 'FACTORY_OR_WAREHOUSE',
    PortOrAirport: 'PORT_OR_AIRPORT',
    BusinessAddress: 'BUSINESS_ADDRESS',
    ResidentialAddress: 'RESIDENTIAL_ADDRESS'
} as const;

export type ShipmentPlanPatchDtoFromAddressTypeEnum = typeof ShipmentPlanPatchDtoFromAddressTypeEnum[keyof typeof ShipmentPlanPatchDtoFromAddressTypeEnum];
export const ShipmentPlanPatchDtoFromCountryEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type ShipmentPlanPatchDtoFromCountryEnum = typeof ShipmentPlanPatchDtoFromCountryEnum[keyof typeof ShipmentPlanPatchDtoFromCountryEnum];
export const ShipmentPlanPatchDtoToAddressTypeEnum = {
    FactoryOrWarehouse: 'FACTORY_OR_WAREHOUSE',
    PortOrAirport: 'PORT_OR_AIRPORT',
    BusinessAddress: 'BUSINESS_ADDRESS',
    ResidentialAddress: 'RESIDENTIAL_ADDRESS'
} as const;

export type ShipmentPlanPatchDtoToAddressTypeEnum = typeof ShipmentPlanPatchDtoToAddressTypeEnum[keyof typeof ShipmentPlanPatchDtoToAddressTypeEnum];
export const ShipmentPlanPatchDtoToCountryEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type ShipmentPlanPatchDtoToCountryEnum = typeof ShipmentPlanPatchDtoToCountryEnum[keyof typeof ShipmentPlanPatchDtoToCountryEnum];

/**
 * 
 * @export
 * @interface ShipmentPlanSummaryDto
 */
export interface ShipmentPlanSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ShipmentPlanSummaryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanSummaryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentPlanSummaryDto
     */
    'shipmentPlanStatus'?: ShipmentPlanSummaryDtoShipmentPlanStatusEnum;
}

export const ShipmentPlanSummaryDtoShipmentPlanStatusEnum = {
    Open: 'OPEN',
    InTransit: 'IN_TRANSIT',
    Received: 'RECEIVED',
    Closed: 'CLOSED',
    Archived: 'ARCHIVED'
} as const;

export type ShipmentPlanSummaryDtoShipmentPlanStatusEnum = typeof ShipmentPlanSummaryDtoShipmentPlanStatusEnum[keyof typeof ShipmentPlanSummaryDtoShipmentPlanStatusEnum];

/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'telephoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'accountId'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof Supplier
     */
    'preferredCurrency'?: Currency;
}

/**
 * OrdersAdminControllerApi - axios parameter creator
 * @export
 */
export const OrdersAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminOrdersForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchAdminOrdersForUser', 'userId', userId)
            const localVarPath = `/admin/orders/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageOrderEntityTransitions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/orders/run-entity-transitions-task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllPackLists: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/orders/sync/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersAdminControllerApi - functional programming interface
 * @export
 */
export const OrdersAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminOrdersForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminOrdersForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersAdminControllerApi.fetchAdminOrdersForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manageOrderEntityTransitions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manageOrderEntityTransitions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersAdminControllerApi.manageOrderEntityTransitions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllPackLists(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllPackLists(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersAdminControllerApi.syncAllPackLists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrdersAdminControllerApi - factory interface
 * @export
 */
export const OrdersAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminOrdersForUser(userId: number, options?: any): AxiosPromise<string> {
            return localVarFp.fetchAdminOrdersForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageOrderEntityTransitions(options?: any): AxiosPromise<string> {
            return localVarFp.manageOrderEntityTransitions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllPackLists(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.syncAllPackLists(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersAdminControllerApi - object-oriented interface
 * @export
 * @class OrdersAdminControllerApi
 * @extends {BaseAPI}
 */
export class OrdersAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersAdminControllerApi
     */
    public fetchAdminOrdersForUser(userId: number, options?: RawAxiosRequestConfig) {
        return OrdersAdminControllerApiFp(this.configuration).fetchAdminOrdersForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersAdminControllerApi
     */
    public manageOrderEntityTransitions(options?: RawAxiosRequestConfig) {
        return OrdersAdminControllerApiFp(this.configuration).manageOrderEntityTransitions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersAdminControllerApi
     */
    public syncAllPackLists(options?: RawAxiosRequestConfig) {
        return OrdersAdminControllerApiFp(this.configuration).syncAllPackLists(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrdersControllerApi - axios parameter creator
 * @export
 */
export const OrdersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {PackListMultipleDeleteDto} [packListMultipleDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultiplePackListsForOrderAndUser: async (userId: number, orderId: number, packListMultipleDeleteDto?: PackListMultipleDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteMultiplePackListsForOrderAndUser', 'userId', userId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('deleteMultiplePackListsForOrderAndUser', 'orderId', orderId)
            const localVarPath = `/orders/{userId}/{orderId}/packlist/delete-multiple`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packListMultipleDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderForUser: async (userId: number, orderId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteOrderForUser', 'userId', userId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('deleteOrderForUser', 'orderId', orderId)
            const localVarPath = `/orders/{userId}/{orderId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {number} packListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackListForOrderAndUser: async (userId: number, orderId: number, packListId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deletePackListForOrderAndUser', 'userId', userId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('deletePackListForOrderAndUser', 'orderId', orderId)
            // verify required parameter 'packListId' is not null or undefined
            assertParamExists('deletePackListForOrderAndUser', 'packListId', packListId)
            const localVarPath = `/orders/{userId}/{orderId}/packlist/{packListId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"packListId"}}`, encodeURIComponent(String(packListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOrderByIdForUser: async (userId: number, orderId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchOrderByIdForUser', 'userId', userId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('fetchOrderByIdForUser', 'orderId', orderId)
            const localVarPath = `/orders/{userId}/{orderId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [orderStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOrdersForUser: async (pageable: Pageable, userId: number, orderStatuses?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchOrdersForUser', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchOrdersForUser', 'userId', userId)
            const localVarPath = `/orders/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (orderStatuses !== undefined) {
                localVarQueryParameter['orderStatuses'] = orderStatuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {OrderPatchPostDto} [orderPatchPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderForUser: async (userId: number, orderId: number, orderPatchPostDto?: OrderPatchPostDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchOrderForUser', 'userId', userId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('patchOrderForUser', 'orderId', orderId)
            const localVarPath = `/orders/{userId}/{orderId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderPatchPostDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {number} packListId 
         * @param {PackListPatchPostDto} [packListPatchPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackListForOrderAndUser: async (userId: number, orderId: number, packListId: number, packListPatchPostDto?: PackListPatchPostDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchPackListForOrderAndUser', 'userId', userId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('patchPackListForOrderAndUser', 'orderId', orderId)
            // verify required parameter 'packListId' is not null or undefined
            assertParamExists('patchPackListForOrderAndUser', 'packListId', packListId)
            const localVarPath = `/orders/{userId}/{orderId}/packlist/{packListId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"packListId"}}`, encodeURIComponent(String(packListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packListPatchPostDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {PackListPatchPostDto} [packListPatchPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackListToOrderForUser: async (userId: number, orderId: number, packListPatchPostDto?: PackListPatchPostDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postPackListToOrderForUser', 'userId', userId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('postPackListToOrderForUser', 'orderId', orderId)
            const localVarPath = `/orders/{userId}/{orderId}/packlist`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packListPatchPostDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {Product} [product] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testProductUpdate: async (userId: number, product?: Product, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('testProductUpdate', 'userId', userId)
            const localVarPath = `/orders/{userId}/test-product`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(product, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersControllerApi - functional programming interface
 * @export
 */
export const OrdersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {PackListMultipleDeleteDto} [packListMultipleDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMultiplePackListsForOrderAndUser(userId: number, orderId: number, packListMultipleDeleteDto?: PackListMultipleDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMultiplePackListsForOrderAndUser(userId, orderId, packListMultipleDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersControllerApi.deleteMultiplePackListsForOrderAndUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrderForUser(userId: number, orderId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrderForUser(userId, orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersControllerApi.deleteOrderForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {number} packListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePackListForOrderAndUser(userId: number, orderId: number, packListId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePackListForOrderAndUser(userId, orderId, packListId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersControllerApi.deletePackListForOrderAndUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOrderByIdForUser(userId: number, orderId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOrderByIdForUser(userId, orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersControllerApi.fetchOrderByIdForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [orderStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOrdersForUser(pageable: Pageable, userId: number, orderStatuses?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOrdersForUser(pageable, userId, orderStatuses, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersControllerApi.fetchOrdersForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {OrderPatchPostDto} [orderPatchPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrderForUser(userId: number, orderId: number, orderPatchPostDto?: OrderPatchPostDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrderForUser(userId, orderId, orderPatchPostDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersControllerApi.patchOrderForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {number} packListId 
         * @param {PackListPatchPostDto} [packListPatchPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPackListForOrderAndUser(userId: number, orderId: number, packListId: number, packListPatchPostDto?: PackListPatchPostDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPackListForOrderAndUser(userId, orderId, packListId, packListPatchPostDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersControllerApi.patchPackListForOrderAndUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {PackListPatchPostDto} [packListPatchPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackListToOrderForUser(userId: number, orderId: number, packListPatchPostDto?: PackListPatchPostDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackListToOrderForUser(userId, orderId, packListPatchPostDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersControllerApi.postPackListToOrderForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {Product} [product] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testProductUpdate(userId: number, product?: Product, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testProductUpdate(userId, product, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersControllerApi.testProductUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrdersControllerApi - factory interface
 * @export
 */
export const OrdersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {PackListMultipleDeleteDto} [packListMultipleDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultiplePackListsForOrderAndUser(userId: number, orderId: number, packListMultipleDeleteDto?: PackListMultipleDeleteDto, options?: any): AxiosPromise<Order> {
            return localVarFp.deleteMultiplePackListsForOrderAndUser(userId, orderId, packListMultipleDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderForUser(userId: number, orderId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteOrderForUser(userId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {number} packListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackListForOrderAndUser(userId: number, orderId: number, packListId: number, options?: any): AxiosPromise<Order> {
            return localVarFp.deletePackListForOrderAndUser(userId, orderId, packListId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOrderByIdForUser(userId: number, orderId: number, options?: any): AxiosPromise<Order> {
            return localVarFp.fetchOrderByIdForUser(userId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [orderStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOrdersForUser(pageable: Pageable, userId: number, orderStatuses?: string, options?: any): AxiosPromise<OrdersListDto> {
            return localVarFp.fetchOrdersForUser(pageable, userId, orderStatuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {OrderPatchPostDto} [orderPatchPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderForUser(userId: number, orderId: number, orderPatchPostDto?: OrderPatchPostDto, options?: any): AxiosPromise<Order> {
            return localVarFp.patchOrderForUser(userId, orderId, orderPatchPostDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {number} packListId 
         * @param {PackListPatchPostDto} [packListPatchPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackListForOrderAndUser(userId: number, orderId: number, packListId: number, packListPatchPostDto?: PackListPatchPostDto, options?: any): AxiosPromise<PackList> {
            return localVarFp.patchPackListForOrderAndUser(userId, orderId, packListId, packListPatchPostDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {PackListPatchPostDto} [packListPatchPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackListToOrderForUser(userId: number, orderId: number, packListPatchPostDto?: PackListPatchPostDto, options?: any): AxiosPromise<PackList> {
            return localVarFp.postPackListToOrderForUser(userId, orderId, packListPatchPostDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {Product} [product] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testProductUpdate(userId: number, product?: Product, options?: any): AxiosPromise<string> {
            return localVarFp.testProductUpdate(userId, product, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersControllerApi - object-oriented interface
 * @export
 * @class OrdersControllerApi
 * @extends {BaseAPI}
 */
export class OrdersControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {number} orderId 
     * @param {PackListMultipleDeleteDto} [packListMultipleDeleteDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public deleteMultiplePackListsForOrderAndUser(userId: number, orderId: number, packListMultipleDeleteDto?: PackListMultipleDeleteDto, options?: RawAxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).deleteMultiplePackListsForOrderAndUser(userId, orderId, packListMultipleDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public deleteOrderForUser(userId: number, orderId: number, options?: RawAxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).deleteOrderForUser(userId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} orderId 
     * @param {number} packListId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public deletePackListForOrderAndUser(userId: number, orderId: number, packListId: number, options?: RawAxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).deletePackListForOrderAndUser(userId, orderId, packListId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public fetchOrderByIdForUser(userId: number, orderId: number, options?: RawAxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).fetchOrderByIdForUser(userId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {string} [orderStatuses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public fetchOrdersForUser(pageable: Pageable, userId: number, orderStatuses?: string, options?: RawAxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).fetchOrdersForUser(pageable, userId, orderStatuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} orderId 
     * @param {OrderPatchPostDto} [orderPatchPostDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public patchOrderForUser(userId: number, orderId: number, orderPatchPostDto?: OrderPatchPostDto, options?: RawAxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).patchOrderForUser(userId, orderId, orderPatchPostDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} orderId 
     * @param {number} packListId 
     * @param {PackListPatchPostDto} [packListPatchPostDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public patchPackListForOrderAndUser(userId: number, orderId: number, packListId: number, packListPatchPostDto?: PackListPatchPostDto, options?: RawAxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).patchPackListForOrderAndUser(userId, orderId, packListId, packListPatchPostDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} orderId 
     * @param {PackListPatchPostDto} [packListPatchPostDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public postPackListToOrderForUser(userId: number, orderId: number, packListPatchPostDto?: PackListPatchPostDto, options?: RawAxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).postPackListToOrderForUser(userId, orderId, packListPatchPostDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {Product} [product] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public testProductUpdate(userId: number, product?: Product, options?: RawAxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).testProductUpdate(userId, product, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrdersPackListControllerApi - axios parameter creator
 * @export
 */
export const OrdersPackListControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {number} purchaseOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPackListsByUserProductPurchaseOrder: async (userId: number, productId: number, purchaseOrderId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchPackListsByUserProductPurchaseOrder', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('fetchPackListsByUserProductPurchaseOrder', 'productId', productId)
            // verify required parameter 'purchaseOrderId' is not null or undefined
            assertParamExists('fetchPackListsByUserProductPurchaseOrder', 'purchaseOrderId', purchaseOrderId)
            const localVarPath = `/orders/{userId}/packlists/{productId}/{purchaseOrderId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"purchaseOrderId"}}`, encodeURIComponent(String(purchaseOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersPackListControllerApi - functional programming interface
 * @export
 */
export const OrdersPackListControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersPackListControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {number} purchaseOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPackListsByUserProductPurchaseOrder(userId: number, productId: number, purchaseOrderId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PackList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPackListsByUserProductPurchaseOrder(userId, productId, purchaseOrderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersPackListControllerApi.fetchPackListsByUserProductPurchaseOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrdersPackListControllerApi - factory interface
 * @export
 */
export const OrdersPackListControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersPackListControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {number} purchaseOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPackListsByUserProductPurchaseOrder(userId: number, productId: number, purchaseOrderId: number, options?: any): AxiosPromise<Array<PackList>> {
            return localVarFp.fetchPackListsByUserProductPurchaseOrder(userId, productId, purchaseOrderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersPackListControllerApi - object-oriented interface
 * @export
 * @class OrdersPackListControllerApi
 * @extends {BaseAPI}
 */
export class OrdersPackListControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {number} purchaseOrderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersPackListControllerApi
     */
    public fetchPackListsByUserProductPurchaseOrder(userId: number, productId: number, purchaseOrderId: number, options?: RawAxiosRequestConfig) {
        return OrdersPackListControllerApiFp(this.configuration).fetchPackListsByUserProductPurchaseOrder(userId, productId, purchaseOrderId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrdersShipmentControllerApi - axios parameter creator
 * @export
 */
export const OrdersShipmentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} shipmentPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFullShipmentPlanForUser: async (userId: number, shipmentPlanId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchFullShipmentPlanForUser', 'userId', userId)
            // verify required parameter 'shipmentPlanId' is not null or undefined
            assertParamExists('fetchFullShipmentPlanForUser', 'shipmentPlanId', shipmentPlanId)
            const localVarPath = `/orders/{userId}/shipmentplans/{shipmentPlanId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"shipmentPlanId"}}`, encodeURIComponent(String(shipmentPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchGoodsTimelineOptionsForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchGoodsTimelineOptionsForUser', 'userId', userId)
            const localVarPath = `/orders/{userId}/shipmentplans/goods-timeline-options`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentCalculateByOptionsForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchShipmentCalculateByOptionsForUser', 'userId', userId)
            const localVarPath = `/orders/{userId}/shipmentplans/calculate-by-options`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentPlanAddressTypesForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchShipmentPlanAddressTypesForUser', 'userId', userId)
            const localVarPath = `/orders/{userId}/shipmentplans/address-types`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentPlanContainerTypesForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchShipmentPlanContainerTypesForUser', 'userId', userId)
            const localVarPath = `/orders/{userId}/shipmentplans/container-types`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentPlanSummariesForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchShipmentPlanSummariesForUser', 'userId', userId)
            const localVarPath = `/orders/{userId}/shipmentplans/summary`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [shipmentStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentPlansForUser: async (userId: number, pageable: Pageable, shipmentStatuses?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchShipmentPlansForUser', 'userId', userId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchShipmentPlansForUser', 'pageable', pageable)
            const localVarPath = `/orders/{userId}/shipmentplans`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (shipmentStatuses !== undefined) {
                localVarQueryParameter['shipmentStatuses'] = shipmentStatuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} shipmentPlanId 
         * @param {ShipmentPlanPatchDto} [shipmentPlanPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShipmentPlanDetailsForUser: async (userId: number, shipmentPlanId: number, shipmentPlanPatchDto?: ShipmentPlanPatchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchShipmentPlanDetailsForUser', 'userId', userId)
            // verify required parameter 'shipmentPlanId' is not null or undefined
            assertParamExists('patchShipmentPlanDetailsForUser', 'shipmentPlanId', shipmentPlanId)
            const localVarPath = `/orders/{userId}/orders/shipmentplans/{shipmentPlanId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"shipmentPlanId"}}`, encodeURIComponent(String(shipmentPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shipmentPlanPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {PackListMultipleDeleteDto} [packListMultipleDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePackListsFromTheirShipmentPlans: async (userId: number, packListMultipleDeleteDto?: PackListMultipleDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removePackListsFromTheirShipmentPlans', 'userId', userId)
            const localVarPath = `/orders/{userId}/shipmentplans/remove-packlists`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packListMultipleDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {ShipmentPlanPackListsDto} [shipmentPlanPackListsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setShipmentPlanPackListsForUser: async (userId: number, shipmentPlanPackListsDto?: ShipmentPlanPackListsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setShipmentPlanPackListsForUser', 'userId', userId)
            const localVarPath = `/orders/{userId}/shipmentplans/packlists`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shipmentPlanPackListsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersShipmentControllerApi - functional programming interface
 * @export
 */
export const OrdersShipmentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersShipmentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} shipmentPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchFullShipmentPlanForUser(userId: number, shipmentPlanId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchFullShipmentPlanForUser(userId, shipmentPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.fetchFullShipmentPlanForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchGoodsTimelineOptionsForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchGoodsTimelineOptionsForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.fetchGoodsTimelineOptionsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchShipmentCalculateByOptionsForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchShipmentCalculateByOptionsForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.fetchShipmentCalculateByOptionsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchShipmentPlanAddressTypesForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchShipmentPlanAddressTypesForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.fetchShipmentPlanAddressTypesForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchShipmentPlanContainerTypesForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchShipmentPlanContainerTypesForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.fetchShipmentPlanContainerTypesForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchShipmentPlanSummariesForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShipmentPlanSummaryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchShipmentPlanSummariesForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.fetchShipmentPlanSummariesForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [shipmentStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchShipmentPlansForUser(userId: number, pageable: Pageable, shipmentStatuses?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageShipmentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchShipmentPlansForUser(userId, pageable, shipmentStatuses, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.fetchShipmentPlansForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} shipmentPlanId 
         * @param {ShipmentPlanPatchDto} [shipmentPlanPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchShipmentPlanDetailsForUser(userId: number, shipmentPlanId: number, shipmentPlanPatchDto?: ShipmentPlanPatchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchShipmentPlanDetailsForUser(userId, shipmentPlanId, shipmentPlanPatchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.patchShipmentPlanDetailsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {PackListMultipleDeleteDto} [packListMultipleDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removePackListsFromTheirShipmentPlans(userId: number, packListMultipleDeleteDto?: PackListMultipleDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PackList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removePackListsFromTheirShipmentPlans(userId, packListMultipleDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.removePackListsFromTheirShipmentPlans']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {ShipmentPlanPackListsDto} [shipmentPlanPackListsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setShipmentPlanPackListsForUser(userId: number, shipmentPlanPackListsDto?: ShipmentPlanPackListsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setShipmentPlanPackListsForUser(userId, shipmentPlanPackListsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersShipmentControllerApi.setShipmentPlanPackListsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrdersShipmentControllerApi - factory interface
 * @export
 */
export const OrdersShipmentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersShipmentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} shipmentPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFullShipmentPlanForUser(userId: number, shipmentPlanId: number, options?: any): AxiosPromise<ShipmentPlan> {
            return localVarFp.fetchFullShipmentPlanForUser(userId, shipmentPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchGoodsTimelineOptionsForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.fetchGoodsTimelineOptionsForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentCalculateByOptionsForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.fetchShipmentCalculateByOptionsForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentPlanAddressTypesForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.fetchShipmentPlanAddressTypesForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentPlanContainerTypesForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.fetchShipmentPlanContainerTypesForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentPlanSummariesForUser(userId: number, options?: any): AxiosPromise<Array<ShipmentPlanSummaryDto>> {
            return localVarFp.fetchShipmentPlanSummariesForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [shipmentStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchShipmentPlansForUser(userId: number, pageable: Pageable, shipmentStatuses?: string, options?: any): AxiosPromise<PageShipmentPlan> {
            return localVarFp.fetchShipmentPlansForUser(userId, pageable, shipmentStatuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} shipmentPlanId 
         * @param {ShipmentPlanPatchDto} [shipmentPlanPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShipmentPlanDetailsForUser(userId: number, shipmentPlanId: number, shipmentPlanPatchDto?: ShipmentPlanPatchDto, options?: any): AxiosPromise<ShipmentPlan> {
            return localVarFp.patchShipmentPlanDetailsForUser(userId, shipmentPlanId, shipmentPlanPatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {PackListMultipleDeleteDto} [packListMultipleDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePackListsFromTheirShipmentPlans(userId: number, packListMultipleDeleteDto?: PackListMultipleDeleteDto, options?: any): AxiosPromise<Array<PackList>> {
            return localVarFp.removePackListsFromTheirShipmentPlans(userId, packListMultipleDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {ShipmentPlanPackListsDto} [shipmentPlanPackListsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setShipmentPlanPackListsForUser(userId: number, shipmentPlanPackListsDto?: ShipmentPlanPackListsDto, options?: any): AxiosPromise<ShipmentPlan> {
            return localVarFp.setShipmentPlanPackListsForUser(userId, shipmentPlanPackListsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersShipmentControllerApi - object-oriented interface
 * @export
 * @class OrdersShipmentControllerApi
 * @extends {BaseAPI}
 */
export class OrdersShipmentControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {number} shipmentPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public fetchFullShipmentPlanForUser(userId: number, shipmentPlanId: number, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).fetchFullShipmentPlanForUser(userId, shipmentPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public fetchGoodsTimelineOptionsForUser(userId: number, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).fetchGoodsTimelineOptionsForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public fetchShipmentCalculateByOptionsForUser(userId: number, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).fetchShipmentCalculateByOptionsForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public fetchShipmentPlanAddressTypesForUser(userId: number, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).fetchShipmentPlanAddressTypesForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public fetchShipmentPlanContainerTypesForUser(userId: number, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).fetchShipmentPlanContainerTypesForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public fetchShipmentPlanSummariesForUser(userId: number, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).fetchShipmentPlanSummariesForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {Pageable} pageable 
     * @param {string} [shipmentStatuses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public fetchShipmentPlansForUser(userId: number, pageable: Pageable, shipmentStatuses?: string, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).fetchShipmentPlansForUser(userId, pageable, shipmentStatuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} shipmentPlanId 
     * @param {ShipmentPlanPatchDto} [shipmentPlanPatchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public patchShipmentPlanDetailsForUser(userId: number, shipmentPlanId: number, shipmentPlanPatchDto?: ShipmentPlanPatchDto, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).patchShipmentPlanDetailsForUser(userId, shipmentPlanId, shipmentPlanPatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {PackListMultipleDeleteDto} [packListMultipleDeleteDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public removePackListsFromTheirShipmentPlans(userId: number, packListMultipleDeleteDto?: PackListMultipleDeleteDto, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).removePackListsFromTheirShipmentPlans(userId, packListMultipleDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {ShipmentPlanPackListsDto} [shipmentPlanPackListsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersShipmentControllerApi
     */
    public setShipmentPlanPackListsForUser(userId: number, shipmentPlanPackListsDto?: ShipmentPlanPackListsDto, options?: RawAxiosRequestConfig) {
        return OrdersShipmentControllerApiFp(this.configuration).setShipmentPlanPackListsForUser(userId, shipmentPlanPackListsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



